// date format: Day, MM/DD/YYYY | xPM-xPM
const upcomingEventsData = [
    {
        id: 1,
        name: "Spring Quarter GBM",
        date: "Thurs, 4/3/2025 | 4PM-5PM",
        img: "spring2025/SpringQuarterGBM2025.png"
    }

]

export default upcomingEventsData;