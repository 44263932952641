const prevEventsData = [
    {
        id: 49,
        name: "Winter Study Jam",
        date: "Mon, 3/10/2025 | 6PM-7:30PM",
        img: "winter2025/WinterStudyJam.png"
    },
    {
        id: 48,
        name: "Winter Talk Series Day 2",
        date: "Thurs, 2/27/2025 | 2PM-4PM",
        img: "winter2025/WinterTalkSeries2.png"
    },
    {
        id: 47,
        name: "Winter Talk Series Day 1",
        date: "Mon, 2/24/2025 | 4PM-5PM",
        img: "winter2025/WinterTalkSeries1.png"
    },
    {
        id: 46,
        name: "Valentine's Arts & Crafts",
        date: "Thurs, 2/13/2025 | 4PM-5PM",
        img: "winter2025/ValentinesCrafts.png"
    },
    {
        id: 45,
        name: "CogSci Advisory Board Welcome Party",
        date: "Fri, 1/31/2025 | 4PM-6PM",
        img: "winter2025/WelcomeParty.png"
    },
    {
        id: 44,
        name: "Figma Workshop",
        date: "Thurs, 1/30/2025 | 5:30PM-6:30PM",
        img: "winter2025/FigmaWorkshop2025.png"
    },
    {
        id: 43,
        name: "Intro to Coding: Portfolio Workshop",
        date: "Wed, 1/22/2025 | 5PM-6PM",
        img: "winter2025/CodingPortfolio.png"
    },
    {
        id: 42,
        name: "Fall GBM",
        date: "Thurs, 10/24/2024 | 5PM-6PM",
        img: "fall2024/FallGBM_1.png"
    },
]

export default prevEventsData;